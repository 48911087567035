import { ReportHandler, Metric } from 'web-vitals';

// A large chunk of this file is modified from:
// - https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-google-tagmanager/src/gatsby-browser.js
// - https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-google-tagmanager/src/gatsby-ssr.js
// to fit our needs.

const listOfMetricsSent = new Set<Metric['name']>();

// See comment about debounce below for why these types aren't in the interface file
type FunctionToDebounce<T> = (...args: Array<T>) => void;
type DebouncedFunction<T> = FunctionToDebounce<T>;

// We have our own debounce utility function; however, this one was implemented slightly differently
// and I wasn't sure if it would cause any issues to swap in our own debounce utility instead
// so I left this one in as a private function for just this file to use.
const debounce = <T>(fn: FunctionToDebounce<T>, timeout?: number): DebouncedFunction<T> => {
  let timer: NodeJS.Timeout = null;

  return (...args: Array<T>): void => {
    if (timer) clearTimeout(timer);

    timer = setTimeout(fn, timeout, ...args);
  };
};

const sendData: ReportHandler = ({ name, value, id }: Metric) => {
  if (listOfMetricsSent.has(name)) return;

  listOfMetricsSent.add(name);

  // Send to GTM
  window.dataLayer.push({
    event: 'core-web-vitals',
    webVitalsMeasurement: {
      name,
      // The `id` value will be unique to the current page load. When sending
      // multiple values from the same page (e.g. for CLS), Google Analytics can
      // compute a total by grouping on this ID (note: requires `eventLabel` to
      // be a dimension in your report).
      id,
      // Google Analytics metrics must be integers, so the value is rounded.
      // For CLS the value is first multiplied by 1000 for greater precision
      // (note: increase the multiplier for greater precision if needed).
      value: Math.round(name === 'CLS' ? value * 1000 : value),
    },
  });
};

const includeInDevelopment: boolean = process.env.GTM_INCLUDE_IN_DEVELOPMENT
  ? `${process.env.GTM_INCLUDE_IN_DEVELOPMENT}`.toLowerCase() === 'true'
  : false;
const isEnabled: boolean = process.env.NODE_ENV === 'production' || includeInDevelopment;
const isWebVitalsTrackingEnabled: boolean = process.env.GTM_ENABLE_WEB_VITALS_TRACKING
  ? `${process.env.GTM_ENABLE_WEB_VITALS_TRACKING}`.toLowerCase() === 'true'
  : true;

export const gatsbyHooks: GoogleTagManagerHelperGatsbyHooks = {
  onClientEntry: (): void => {
    // Make sure dataLayer is still defined even when GTM isn't loaded
    window.dataLayer = window.dataLayer || [];
  },

  onInitialClientRender: (): void => {
    // We only load the polyfill in production so we can't enable it in development.
    if (process.env.NODE_ENV !== 'production' || !isWebVitalsTrackingEnabled || !isEnabled) return;

    document.addEventListener('load', () => {
      // Send web vitals
      import('web-vitals/base').then(({ getLCP, getFID, getCLS }) => {
        const debouncedCLS: ReportHandler = debounce(sendData, 3000);
        // LCP can occur multiple times so we debounce it
        const debouncedLCP: ReportHandler = debounce(sendData, 3000);

        // With the true flag, we measure all previous occurences too, in case we start listening to late.
        getCLS(debouncedCLS, true);
        // We don't need to debounce FID - we send it when it happens.
        getFID(sendData, true);
        getLCP(debouncedLCP, true);
      });
    });
  },
  onRouteUpdate: (): void => {
    if (!isEnabled) return;

    // Wrap inside a timeout to ensure the title has properly been changed.
    setTimeout((): void => {
      window.dataLayer.push({ event: 'gatsby-route-change' });
    }, 50);
  },
};

export const idByLanguage: GoogleTagManagerHelper['idByLanguage'] = {
  'en-US': 'GTM-5CB8FPH',
  'es-MX': 'GTM-NVK5MC5',
};

const googleTagManagerHelper: GoogleTagManagerHelper = {
  getScriptInnerHTML: googleTagManagerKey =>
    `!function(b,d,e,a){b[a]=b[a]||[],b[a].push({"gtm.start":new Date().getTime(),event:"gtm.js"});var f=d.getElementsByTagName(e)[0],c=d.createElement(e);c.async=!0,c.src="https://www.googletagmanager.com/gtm.js?id=${googleTagManagerKey}",f.parentNode.insertBefore(c,f)}(window,document,"script","dataLayer")`,
  idByLanguage,
  isEnabled,
  isWebVitalsTrackingEnabled,
  webVitalsPolyfillScriptInnerHTML:
    '!function(){var e,t,n,i,r={passive:!0,capture:!0},a=new Date,o=function(){i=[],t=-1,e=null,f(addEventListener)},c=function(i,r){e||(e=r,t=i,n=new Date,f(removeEventListener),u())},u=function(){if(t>=0&&t<n-a){var r={entryType:"first-input",name:e.type,target:e.target,cancelable:e.cancelable,startTime:e.timeStamp,processingStart:e.timeStamp+t};i.forEach((function(e){e(r)})),i=[]}},s=function(e){if(e.cancelable){var t=(e.timeStamp>1e12?new Date:performance.now())-e.timeStamp;"pointerdown"==e.type?function(e,t){var n=function(){c(e,t),a()},i=function(){a()},a=function(){removeEventListener("pointerup",n,r),removeEventListener("pointercancel",i,r)};addEventListener("pointerup",n,r),addEventListener("pointercancel",i,r)}(t,e):c(t,e)}},f=function(e){["mousedown","keydown","touchstart","pointerdown"].forEach((function(t){return e(t,s,r)}))},p="hidden"===document.visibilityState?0:1/0;addEventListener("visibilitychange",(function e(t){"hidden"===document.visibilityState&&(p=t.timeStamp,removeEventListener("visibilitychange",e,!0))}),!0);o(),self.webVitals={firstInputPolyfill:function(e){i.push(e),u()},resetFirstInputPolyfill:o,get firstHiddenTime(){return p}}}();',
};

export default googleTagManagerHelper;
