import { GatsbyBrowser } from 'gatsby';

import { gatsbyHooks as googleTagManagerGatsbyHooks } from '../../src/utils/googleTagManagerHelper';

export const onClientEntry: GatsbyBrowser['onClientEntry'] = (): void => {
  googleTagManagerGatsbyHooks.onClientEntry();
};

export const onInitialClientRender: GatsbyBrowser['onInitialClientRender'] = (): void => {
  googleTagManagerGatsbyHooks.onInitialClientRender();
};

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = (): void => {
  googleTagManagerGatsbyHooks.onRouteUpdate();
};
